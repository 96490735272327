export default class Routes {
  static LANDING = '/'
  static SIGN_UP = '/signup'
  static SIGN_IN = '/signin'
  static HOME = '/home'
  static ACCOUNT = '/account'
  static CONSULTATION_FORMS = '/consultation-forms'
  static APPOINTMENTS = '/appointments'
  static PAYMENTS = '/payments'
  static DELETE_ACCOUNT = '/delete-account'
  static ADMIN = '/admin'
}
