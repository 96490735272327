import { Paper } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Title from '../../portal/Title'
import userClient from '../../hooks/useClient'
import { withAuthoriztion, loggedIn } from '../Session'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: '25ch',
  },
}))

function Account(props: any) {
  const classes = useStyles()
  const { client, loading } = userClient(props.authUser.uid)

  if (loading) {
    return 'loading...'
  }

  if (!client) {
    // todo what do we do here
    return 'Unable to find your profile details'
  }

  return (
    <Paper className={'bob'}>
      <div className={classes.root}>
        <Title>Profile</Title>
        <TextField
          id="outlined-full-width"
          label="First Name"
          style={{ margin: 8 }}
          placeholder="Placeholder"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={client.firstname}
        />
        <TextField
          id="outlined-full-width"
          label="Last Name"
          style={{ margin: 8 }}
          placeholder="Placeholder"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={client.lastname}
        />
        <TextField
          id="outlined-full-width"
          label="Phone Number"
          style={{ margin: 8 }}
          placeholder="Placeholder"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={client.phonenumber}
        />
      </div>
    </Paper>
  )
}

export default withAuthoriztion(loggedIn)(Account)
