import React from 'react'
import { withRouter } from 'react-router-dom'

import AuthUserContext from './context'
import { withFirebase } from '../Firebase'
import ROUTES from '../../constants/routes'
import { compose } from 'recompose'

// likely the default condition to be used, is the user logged in
export const loggedIn = (authUser: any) => !!authUser

/**
Given a condition with check the state of the authUser from the context and
conditionally render the component, good for hiding contents of auth required
routes and pushing them to the signin page
*/
const withAuthorization = (condition: any) => (Component: any) => {
  class WithAuthorization extends React.Component {
    private listener: any

    componentDidMount() {
      //@ts-ignore
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        (authUser: any) => {
          if (!condition(authUser)) {
            //@ts-ignore``
            this.props.history.push(ROUTES.SIGN_IN)
          }
        }
      )
    }

    componentWillUnmount() {
      this.listener()
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {(authUser) =>
            condition(authUser) ? (
              <Component {...this.props} authUser={authUser} />
            ) : null
          }
        </AuthUserContext.Consumer>
      )
    }
  }

  return compose(withRouter, withFirebase)(WithAuthorization as any)
}

export default withAuthorization
