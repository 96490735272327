import AppBar from '@material-ui/core/AppBar'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Toolbar from '@material-ui/core/Toolbar'
import AssignmentIcon from '@material-ui/icons/Assignment'
import DashboardIcon from '@material-ui/icons/Dashboard'
import HomeIcon from '@material-ui/icons/Home'
import MenuIcon from '@material-ui/icons/Menu'
import PeopleIcon from '@material-ui/icons/People'
import React from 'react'
import clsx from 'clsx'

import { withFirebase } from '../components/Firebase'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { AuthUserContext } from '../components/Session'
import { withRouter } from 'react-router-dom'
import Routes from '../constants/routes'

import DarkLogo from '../images/toolkit/thebeautyapp_brandingtoolkit-07-dark-logo.png'
import useStyles from './css/portal-theme'

export const mainListItems = (changeToPage) => (
  <div>
    <AuthUserContext.Consumer>
      {(authUser) =>
        authUser ? (
          <>
            <ListItem button>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText
                primary="Home"
                onClick={changeToPage(Routes.LANDING)}
              />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText
                primary="Profile"
                onClick={changeToPage(Routes.ACCOUNT)}
              />
            </ListItem>
            {/*<ListItem button>*/}
            {/*<ListItemIcon>*/}
            {/*<ShoppingCartIcon />*/}
            {/*</ListItemIcon>*/}
            {/*<ListItemText*/}
            {/*primary="Forms"*/}
            {/*onClick={changeToPage(Routes.CONSULTATION_FORMS)}*/}
            {/*/>*/}
            {/*</ListItem>*/}
            <ListItem button>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText
                primary="Appointments"
                onClick={changeToPage(Routes.APPOINTMENTS)}
              />
            </ListItem>
            {/*<ListItem button>*/}
            {/*<ListItemIcon>*/}
            {/*<BarChartIcon />*/}
            {/*</ListItemIcon>*/}
            {/*<ListItemText*/}
            {/*primary="Payments"*/}
            {/*onClick={changeToPage(Routes.PAYMENTS)}*/}
            {/*/>*/}
            {/*</ListItem>*/}
          </>
        ) : (
          <>
            <ListItem button>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText
                primary="Home"
                onClick={changeToPage(Routes.HOME)}
              />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText
                primary="Profile"
                onClick={changeToPage(Routes.ACCOUNT)}
              />
            </ListItem>
            {/*<ListItem button>*/}
            {/*<ListItemIcon>*/}
            {/*<ShoppingCartIcon />*/}
            {/*</ListItemIcon>*/}
            {/*<ListItemText*/}
            {/*primary="Forms"*/}
            {/*onClick={changeToPage(Routes.CONSULTATION_FORMS)}*/}
            {/*/>*/}
            {/*</ListItem>*/}
            <ListItem button>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText
                primary="Appointments"
                onClick={changeToPage(Routes.APPOINTMENTS)}
              />
            </ListItem>
          </>
        )
      }
    </AuthUserContext.Consumer>
  </div>
)

interface SecondaryItems {
  onLoginClick: any
  onLogoutClick: any
}

export const LogInOutItems = (props: SecondaryItems) => (
  <div>
    {/*<ListSubheader inset>Saved reports</ListSubheader>*/}
    <AuthUserContext.Consumer>
      {(authUser) =>
        authUser ? (
          <ListItem button>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Log Out" onClick={props.onLogoutClick} />
          </ListItem>
        ) : (
          <ListItem button>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Login" onClick={props.onLoginClick} />
          </ListItem>
        )
      }
    </AuthUserContext.Consumer>
  </div>
)

function LeftAndTopNavigation(props: any) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  const changeToPage = (route: Routes) => () => {
    props.history.push(route)
  }

  const onLogoutClick = () => {
    props.firebase.doSignOut()
    props.history.push(Routes.LANDING)
  }

  return (
    <>
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <img src={DarkLogo} alt="Logo" className={classes.appBarLogo} />
          <div className={classes.filler}></div>
          {/*<Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>*/}
          {/*LOGO*/}
          {/*</Typography>*/}
          <Typography component="h1" variant="h6" color="inherit" noWrap>
            The Beauty App
          </Typography>
          {/*<IconButton color="inherit">*/}
          {/*<Badge badgeContent={1} color="secondary">*/}
          {/*<NotificationsIcon />*/}
          {/*</Badge>*/}
          {/*</IconButton>*/}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems(changeToPage)}</List>
        <Divider />
        <List>
          <LogInOutItems
            onLoginClick={changeToPage(Routes.SIGN_IN)}
            onLogoutClick={onLogoutClick}
          ></LogInOutItems>
          {/*{secondaryListItems(onLoginClick, onLogoutClick)}*/}
        </List>
      </Drawer>
    </>
  )
}

export default withFirebase(withRouter(LeftAndTopNavigation))
