import * as firebaseApp from 'firebase/app'
import firebase from 'firebase'
import 'firebase/auth'
import Routes from '../../constants/routes'

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_DATABASE_URL,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
} = process.env

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: REACT_APP_FIREBASE_DATABASE_URL,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
}

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to a route after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: Routes.APPOINTMENTS,
  signInOptions: [firebase.auth.PhoneAuthProvider.PROVIDER_ID],
  recaptchaParameters: {
    size: 'invisible',
  },
}

export class Firebase {
  private firebaseDb: firebase.database.Database
  private auth: firebase.auth.Auth
  private user: firebase.User

  constructor() {
    firebaseApp.initializeApp(firebaseConfig)
    this.auth = firebaseApp.auth()
    this.auth.onAuthStateChanged(this.setUser.bind(this))
    this.firebaseDb = firebase.database()
    this.user = null as any
  }

  getAuth = () => {
    return this.auth
  }

  getUiConfig = () => {
    return uiConfig
  }

  setUser = (user: any) => {
    this.user = user as firebase.User
  }

  getDatabase = () => {
    return this.firebaseDb
  }

  getUser = () => {
    return this.user
  }

  isLoggedIn = () => {
    return !!this.user
  }

  doSignOut = () => this.auth.signOut()

  // doSignInWithPhoneNumber = (phoneNumber:string, applicationVerifier:firebase.auth.ApplicationVerifier) => {
  //   const self = this;
  //   return this.auth.signInWithPhoneNumber(phoneNumber, applicationVerifier)
  //     .then(confirmationResult => {
  //       // SMS sent. Prompt user to type the code from the message, then sign the
  //       // user in with confirmationResult.confirm(code)
  //       const verificationCode = window.prompt('Please enter your SMS confirmation code');
  //       if(!!!verificationCode) {
  //         return confirmationResult.confirm(verificationCode || '')
  //           .then(confrimationResult => {
  //             // @ts-ignore
  //             self.user =  confrimationResult.user
  //           })
  //       }
  //       throw new Error(`Invalid code provided of "${verificationCode}"`);
  //     }).catch(error => {
  //       console.error(error)
  //     })
  // }
  //
  // getRecaptchaChallange = (onSignInSubmit: () => {}, elementToBindTo: string) => {
  //   new firebaseApp.auth.RecaptchaVerifier(elementToBindTo, {
  //     'size': 'invisible',
  //     'callback': (response: unknown) => {
  //       // reCAPTCHA solved, allow signInWithPhoneNumber.
  //       onSignInSubmit();
  //     }
  //   });
  // }
}

export default new Firebase()
