import React from 'react'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { loggedIn, withAuthoriztion } from '../components/Session'
import userClient, { ClientAppointment } from '../hooks/useClient'
import SplashScreen from '../images/splash-drop.png'
import Title from './Title'

function preventDefault(event) {
  event.preventDefault()
}

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: 'white',
    color: 'black',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  filler: {
    flexGrow: 1,
  },
  appBarLogo: {
    height: 64,
  },
  heroImg: {
    color: 'white',
    flexGrow: 1,
    maxHeight: 600,
    height: '500px',
    backgroundImage: `url(${SplashScreen})`,
    backgroundPositionX: 0,
    backgroundPositionY: '50%',
    backgroundSize: 'cover',
    textAlign: 'center',
  },
  heroLoginButton: {
    backgroundColor: '#e7e1db',
    color: '#000',
    textAlign: 'center',
    padding: '16px 35px',
    fontSize: '24px',
    lineHeight: '26px',
    fontWeight: 'bold',
    borderRadius: 0,
  },
  heroContentOuter: {
    fontWeight: 'bold',
    fontSize: '2em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& h1': {
      marginBottom: 0,
    },
    '& h2': {
      marginBottom: '4px',
      marginTop: '4px',
    },
  },
  heroContentItem: {
    maxWidth: '80%',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(0),
    // width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(0),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}))

function AppointmentRow(row: ClientAppointment) {
  return (
    <TableRow key={row.appointmentId}>
      <TableCell>{row.date}</TableCell>
      <TableCell>{row.menuItem.subcategory}</TableCell>
      <TableCell>{row.menuItem.service.clientTime}</TableCell>
      <TableCell>{row.salonNameSnapshot}</TableCell>
      <TableCell>{row.staffNameSnapshot}</TableCell>
      {/*<TableCell align="right">{row.staffNameSnapshot}</TableCell>*/}
    </TableRow>
  )
}

function Appointments(props: any) {
  const classes = useStyles()
  const { client, loading } = userClient(props.authUser.uid)

  if (loading) {
    return 'Loading...'
  }

  if (!client) {
    // todo what do we do here
    return 'Unable to find your profile details'
  }

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Title>
            Appointments
            {Object.keys(client.appointments || []).length === 0
              ? ' (No Appointments found)'
              : ''}
          </Title>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Date & Time</TableCell>
                <TableCell>Treatment</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Salon Name</TableCell>
                <TableCell>Staff Member</TableCell>
                {/*<TableCell align="right">Staff Member</TableCell>*/}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(client.appointments).map((key) =>
                AppointmentRow(client.appointments[key])
              )}
            </TableBody>
          </Table>
          <div>
            <Link color="primary" href="#" onClick={preventDefault}>
              See more orders
            </Link>
          </div>
        </Paper>
      </Grid>
    </React.Fragment>
  )
}

export default withAuthoriztion(loggedIn)(Appointments)
