import React from 'react'
import ReactDOM from 'react-dom'
import 'fontsource-roboto'
import './index.css'
// import App from './components/App/index'
import Firebase, { FirebaseContext } from './components/Firebase'
import * as serviceWorker from './serviceWorker'
import Dashboard from './portal/Dashboard'
import { BrowserRouter as Router } from 'react-router-dom'

const Index = () => {
  return (
    <FirebaseContext.Provider value={Firebase}>
      <Router>
        <Dashboard></Dashboard>
      </Router>
      {/*<App/>*/}
    </FirebaseContext.Provider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
