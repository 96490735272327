import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Routes from '../../constants/routes'
import { Redirect } from 'react-router-dom'
import { AuthUserContext } from '../Session'
import { withFirebase } from '../Firebase'

const useStyles = makeStyles((theme) => ({
  paperSignIn: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    paddingBottom: '80px',
    textAlign: 'center',
  },
}))

function SignInPage(props: any) {
  const classes = useStyles()

  const { firebase } = props

  if (firebase === null) {
    return null
  }
  return (
    <Grid item xs={12}>
      <Paper className={classes.paperSignIn}>
        <div>
          <h1>Sign In</h1>
          <StyledFirebaseAuth
            uiConfig={firebase.getUiConfig()}
            firebaseAuth={firebase.getAuth()}
          />
        </div>
      </Paper>
    </Grid>
  )
}

class SignIn extends React.Component<any> {
  render() {
    const { firebase } = this.props

    if (firebase === null) return
    return (
      <AuthUserContext.Consumer>
        {(authUser) =>
          authUser ? (
            <Redirect to={Routes.HOME} />
          ) : (
            <SignInPage firebase={firebase}></SignInPage>
          )
        }
      </AuthUserContext.Consumer>
    )
  }
}

export default withFirebase(SignIn)
