import React from 'react'
import { withAuthoriztion, loggedIn } from '../Session'

class Home extends React.Component {
  render() {
    //@ts-ignore
    const { firebase } = this.props
    if (firebase === null) return
    // is logged in check not needed because of the loggedIn condition, will auto redirect
    return (
      <div>
        <h1>Home</h1>
        <p>{firebase.isLoggedIn() ? 'User signed in' : 'User signed out'}</p>
      </div>
    )
  }
}

export default withAuthoriztion(loggedIn)(Home)
