import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import clsx from 'clsx'
import React from 'react'
import DarkLogo from '../../images/toolkit/thebeautyapp_brandingtoolkit-07-dark-logo.png'
import useStyles from '../../portal/css/portal-theme'

function renderRow(style: any, title: any, content: any, image?: any) {
  return (
    <>
      <Grid item xs={12} md={8}>
        <Paper className={style}>
          <h3>{title}</h3>
          <p>{content}</p>
        </Paper>
      </Grid>
      {/* Right*/}
      <Grid item xs={12} md={4}>
        <Paper className={style}>
          <img src={DarkLogo} alt="Logo" className="row-image" />
        </Paper>
      </Grid>
    </>
  )
}

function renderRowLeft(style: any, title: any, content: any, image?: any) {
  return (
    <>
      {/* Left*/}
      <Grid item xs={12} md={4}>
        <Paper className={style}>
          <img src={DarkLogo} alt="Logo" className="row-image" />
        </Paper>
      </Grid>
      {/* Right*/}
      <Grid item xs={12} md={8}>
        <Paper className={style}>
          <h3>{title}</h3>
          <p>{content}</p>
        </Paper>
      </Grid>
    </>
  )
}

const rowData = {
  whatIsTheBeautyApp: {
    title: 'What is the Beauty App Client Portal?',
    content:
      '    The client portal lets YOU control your information and appointments\n' +
      '    whilst allowing you to link the details that you choose to your beauty\n' +
      '    provider',
  },
  yourProfile: {
    title: 'Your Profile',
    content:
      'List and edit your personal details and preferences, making your beauty\n' +
      '    experience easy and personal',
  },
  consultationForms: {
    title: 'Consultation Forms',
    content:
      'The Beauty App puts YOU in control of your personal details. Keep all of\n' +
      '    your consultation forms in one place and fill them in, when it suits you\n' +
      '    You can amend/edit your forms and also choose to send them to the beauty\n' +
      '    provider of your choice prior to a treatment. Any changes/updates made to\n' +
      '    your form as a result of a treatment will automatically be stored I your\n' +
      '    portal',
  },
  myAppointments: {
    title: 'My Appointments',
    content:
      'At a glance, see your past and upcoming appointments, giving you a record\n' +
      '    of what treatments, you have had or are about to enjoy',
  },
  payments: {
    title: 'Payments',
    content:
      'Store and edit your card payment detail securely for use of buying online\n' +
      '    cancellations from the app, or contactless payments in store',
  },
  deleteAccount: {
    title: 'Delete Account',
    content:
      'Should you wish to leave The Beauty App, you can delete your portal by\n' +
      '    going to this section',
  },
  theCancellationsApp: {
    title: 'The Cancellations App',
    content:
      'Not only will the app fill your last-minute cancellations and late\n' +
      '    availability, by advertising (for free) your availability you also let\n' +
      '    clients know you are there and what you do. Whilst they may not buy your\n' +
      '    current offering, they have the option to store you as a favourite and use\n' +
      '    your services at a later date. They also have the option of sending you to\n' +
      '    a friend further increasing your reach. Not only does this cost your\n' +
      '    business nothing but there is nothing for you to do- the app take your\n' +
      '    cancellations and late availability automatically from your (The Beauty\n' +
      '    app PRO) booking system and notifies all clients in your area of what you\n' +
      '    have available. No cost no hassle- how great is that',
  },
  theClientPortal: {
    title: 'The Client Portal',
    content:
      'All clients of The Beauty App have free access to their own client portal\n' +
      '    (The client portal lets YOU control your information and appointments\n' +
      '    whilst allowing you to link the details that you choose to your beauty\n' +
      '    provider). For a small fee you can directly market your service to live\n' +
      '    clients in your area who use your type of services. There is a save\n' +
      '    favourites function within the portal that allows clients to save your\n' +
      '    details or send them to a friend',
  },
  rewards: {
    title: 'Rewards',
    content:
      'The Beauty app is proud to work with Rewardful in providing a loyalty and\n' +
      '    referral scheme to your clients for using your service and referring a\n' +
      '    friend. Make sure that your business is signed up to take advantage of\n' +
      '    these schemes',
  },
}

const Landing = () => {
  const classes = useStyles()
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)

  return (
    <>
      {renderRow(
        fixedHeightPaper,
        rowData.whatIsTheBeautyApp.title,
        rowData.whatIsTheBeautyApp.content
      )}
      {renderRowLeft(
        fixedHeightPaper,
        rowData.yourProfile.title,
        rowData.yourProfile.content
      )}
      {/*{renderRow(*/}
      {/*  fixedHeightPaper,*/}
      {/*  rowData.consultationForms.title,*/}
      {/*  rowData.consultationForms.content*/}
      {/*)}*/}
      {renderRow(
        fixedHeightPaper,
        rowData.myAppointments.title,
        rowData.myAppointments.content
      )}
      {/*{renderRow(*/}
      {/*  fixedHeightPaper,*/}
      {/*  rowData.payments.title,*/}
      {/*  rowData.payments.content*/}
      {/*)}*/}
      {/*{renderRowLeft(*/}
      {/*  fixedHeightPaper,*/}
      {/*  rowData.deleteAccount.title,*/}
      {/*  rowData.deleteAccount.content*/}
      {/*)}*/}
    </>
  )
}

export default Landing
