import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { withAuthentication } from '../components/Session'
import Appointments from './Appointments'
import './dashboard.css'
import { Route, withRouter } from 'react-router-dom'
import ROUTES from '../constants/routes'
import SignInPage from '../components/SignIn'
import HomePage from '../components/Home'
import AccountPage from '../components/Account'
import AdminPage from '../components/Admin'

import LeftAndTopNavigation from './LeftAndTopNavigation'
import Landing from '../components/Landing'
import useStyles from './css/portal-theme'
import Routes from '../constants/routes'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © ' + new Date().getFullYear()} - The Beauty App Ltd
      <br />
      Springbank House, West Mains Rd, Glasgow, G74 1PT
    </Typography>
  )
}

function Dashboard(props) {
  const classes = useStyles()
  const loginClick = () => {
    props.history.push(Routes.SIGN_IN)
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <LeftAndTopNavigation></LeftAndTopNavigation>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3} justify={'center'}>
            {/*<Route exact path={ROUTES.LANDING} component={LandingPage}/>*/}
            <Route exact path={ROUTES.LANDING}>
              <Grid item xs={12}>
                <div className={classes.heroImg}>
                  <div className={classes.heroContentOuter}>
                    <div className={classes.heroContentItem}>
                      <h1>Let us power your salon for Free!</h1>
                    </div>
                    {/* todo add logic to hide login button if signed in already */}
                    <Button
                      variant="contained"
                      color="default"
                      className={classes.heroLoginButton}
                      onClick={loginClick}
                    >
                      Login
                    </Button>
                  </div>
                </div>
              </Grid>
              <Landing></Landing>
            </Route>
            <Route path={ROUTES.SIGN_IN} component={SignInPage} />
            <Route path={ROUTES.HOME} component={HomePage} />
            <Route path={ROUTES.ACCOUNT} component={AccountPage} />
            <Route path={ROUTES.APPOINTMENTS} component={Appointments} />
            <Route path={ROUTES.ADMIN} component={AdminPage} />
            {/*</div>*/}
            {/* Recent Orders */}

            {/*/!* Chart *!/*/}
            {/*<Grid item xs={12} md={8} lg={9}>*/}
            {/*<Paper className={fixedHeightPaper}>*/}
            {/*<Chart />*/}
            {/*</Paper>*/}
            {/*</Grid>*/}
            {/*/!* Recent Deposits *!/*/}
            {/*<Grid item xs={12} md={4} lg={3}>*/}
            {/*<Paper className={fixedHeightPaper}>*/}
            {/*<Deposits />*/}
            {/*</Paper>*/}
            {/*</Grid>*/}

            {/*/!* Chart *!/*/}
            {/*<Grid item xs={12} md={8} lg={9}>*/}
            {/*<Paper className={fixedHeightPaper}>*/}
            {/*<Chart />*/}
            {/*</Paper>*/}
            {/*</Grid>*/}

            {/*/!* Recent Orders *!/*/}
            {/*<Grid item xs={12}>*/}
            {/*<Paper className={classes.paper}>*/}
            {/*<Orders />*/}
            {/*</Paper>*/}
            {/*</Grid>*/}
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  )
}

export default withAuthentication(withRouter(Dashboard))
