import firebase from '../components/Firebase/firebase'
import { useObjectVal } from 'react-firebase-hooks/database'

export type ClientAppointment = {
  appointmentId: string // the appointmentId in the appointments/{id} branch
  duration: number // in minutes
  date: string // probably need to parse this
  customerId: string //
  price: number // in cents
  salonId: string
  salonNameSnapshot: string
  staffId: string
  staffNameSnapshot: string
  menuItem: {
    category: string
    subcategory: string
    service: {
      clientTime: string // in minutes
      price: string // in cents
      staffTime: string // in minutes
    }
  }
}

export type Client = {
  appointments: { [key: string]: ClientAppointment }
  appointments_copy: any[]
  firstname?: string
  lastname?: string
  phonenumber: string
  purchased_appointments: any[] // ???
  salons: {
    [salonId: string]: string // salonId: customerId
  }
  stripebuyingcustid?: string
  stripedefaultcardid?: string
}

export const useClient = (authId: string) => {
  const [client, loading] = useObjectVal<Client>(
    firebase.getDatabase().ref(`user_profile_mobapp_customers/${authId}`)
  )

  return { client, loading }
}

export default useClient
